<script setup lang="ts">
import { useForm } from '@getmo/common/vendor/vee-validate';
import { LoginStatus, verifyWhatsappOtp } from '~/shared/endpoints/session';

const props = defineProps<{
  submittedPhone: string;
  sessionId: string | undefined;
}>();
const emit = defineEmits<{
  goBack: [];
  verified: [];
  noSuchUser: [];
}>();

const { t } = useI18n({ useScope: 'local' });
const invalidOtp = ref<null | string>(null);
const { defineField, handleSubmit, errors, isFieldTouched, validate, isSubmitting } = useForm({
  initialValues: { otp: '' },
  validationSchema: {
    otp: (value: string) => {
      if (value.length !== 6) {
        return 'Enter the code';
      }

      if (value === invalidOtp.value) {
        return 'Invalid code';
      }

      return true;
    },
  },
});
const [otp] = defineField('otp');

const onSubmit = handleSubmit(async (values) => {
  try {
    const { status } = await callApi(verifyWhatsappOtp, {
      body: {
        phone: props.submittedPhone,
        sessionId: props.sessionId,
        otp: values.otp,
      },
    });
    switch (status) {
      case LoginStatus.OtpInvalid: {
        invalidOtp.value = values.otp;
        await validate();
        break;
      }

      case LoginStatus.NoSuchUser: {
        emit('noSuchUser');
        break;
      }

      case LoginStatus.Success: {
        emit('verified');
        break;
      }

      default: {
        throw new Error('Unexpected status');
      }
    }
  } catch (error) {
    log.exception(error);
  }
});
</script>

<template>
  <h2 :class="$style.title">{{ t('enter-code') }}</h2>
  <p :class="$style.hint">
    We sent it to {{ submittedPhone }}.
    <Button :class="$style.changeNumber" link @click="$emit('goBack')">Change number</Button>
  </p>

  <form :class="$style.form" @submit="onSubmit">
    <OurInputOtp v-model="otp" autofocus :length="6" @finish="onSubmit()" />
    <InputError
      :class="$style.error"
      :message="errors.otp"
      :inputClass="{ 'p-invalid': isFieldTouched('otp') && errors.otp }"
    />
    <Button :class="[$style.button, $style.submit]" type="submit" :loading="isSubmitting" :label="t('submit')" />
    <Button :class="$style.button" type="button" :disabled="isSubmitting" :label="t('resend')" outlined />
  </form>
</template>

<style module>
.title {
  margin: 0 0 20px;
  font: var(--font-strong) var(--font-52);
  text-wrap: nowrap;
}

.hint {
  margin: 0 0 60px;
  font-weight: normal;
  color: var(--color-text-secondary);
  text-align: center;
}

.changeNumber {
  padding: 0;
  font-weight: normal;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;

  .error {
    margin-bottom: 24px;
  }
}

.button {
  display: block;
  height: 54px;
  border-radius: var(--border-radius);
}

.submit {
  margin-bottom: 12px;
  color: var(--color-text-action);
  background: var(--color-primary);
}
</style>
