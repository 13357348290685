<i18n src="./PhoneInput.i18n.json" />

<script setup lang="ts">
import { phone as phoneFormat } from '@getmo/common/utils/formats/phone';
import { useForm } from '@getmo/common/vendor/vee-validate';
import { sendWhatsappOtp } from '~/shared/endpoints/session';

const props = defineProps<{
  previousPhone: string;
}>();
const emit = defineEmits<{
  submit: [data: { phone: string; sessionId: string | undefined }];
}>();

const cleanPhone = (p: string) => p.replace(/ /g, '').replace(/-/g, '');
const { phoneMask, phonePlaceholder } = useBrandSettings();
const minLength = cleanPhone(phoneMask.replace(/\?.+$/, '')).length;
const { t } = useI18n();
const { meta, isSubmitting, handleSubmit, defineField, errors, isFieldTouched, setFieldValue } = useForm<{
  phone: string;
}>({
  initialValues: { phone: props.previousPhone },
  initialErrors: { phone: props.previousPhone ? 'No application linked to this phone' : undefined },
  initialTouched: { phone: !!props.previousPhone },
  validationSchema: {
    phone: (value: string) => {
      const cleaned = cleanPhone(value);
      if (cleaned === props.previousPhone) {
        return 'No application linked to this phone';
      }

      if (cleaned.length >= minLength) {
        return true;
      }

      return 'Invalid phone';
    },
  },
});

const [phone, phoneAttrs] = defineField('phone');

const onSubmit = handleSubmit(async (values) => {
  try {
    const submittedPhone = cleanPhone(values.phone);
    const { sessionId } = await callApi(sendWhatsappOtp, {
      body: { phone: submittedPhone },
    });
    emit('submit', { phone: submittedPhone, sessionId });
  } catch (error) {
    log.exception(error);
  }
});
const onKeyDown = (e: Event) => {
  // For some reason, primevue InputMask doesn't submit the form on enter press
  if (e instanceof KeyboardEvent && e.key === 'Enter') {
    return onSubmit();
  }
};
const onPaste = (e: Event) => {
  if (e instanceof ClipboardEvent && e.clipboardData) {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    setFieldValue('phone', phoneFormat.extractMaskedPhone(phoneMask, text));
  }
};
</script>

<template>
  <h2 :class="$style.title">{{ t('sign-in') }}</h2>

  <form :class="$style.form" @submit="onSubmit">
    <InputMask
      v-model="phone"
      :class="[$style.phone, { 'p-invalid': errors.phone && isFieldTouched('phone') }]"
      autofocus
      v-bind="phoneAttrs"
      :mask="phoneMask"
      :placeholder="phonePlaceholder"
      :autoClear="false"
      slotChar=" "
      @keydown="onKeyDown"
      @paste="onPaste"
    />

    <InputError :message="isFieldTouched('phone') ? errors.phone : undefined" />
    <Button
      type="submit"
      color="primary"
      :class="$style.button"
      :loading="isSubmitting"
      :disabled="!meta.valid"
      :label="t('next')"
    />
  </form>
</template>

<style module>
.title {
  margin-bottom: 80px;
  font: var(--font-strong) var(--font-52);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  max-width: 320px;

  .phone {
    display: block;
    height: 56px;
    padding: 8px 16px;
    border-radius: var(--border-radius);
  }

  .button {
    display: flex;
    height: 54px;
    margin-top: 24px;
    border-radius: var(--border-radius);
  }
}
</style>
