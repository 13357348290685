<script setup lang="ts">
import OtpInput from './_components/OtpInput.vue';
import PhoneInput from './_components/PhoneInput.vue';
import { Type } from '@getmo/common/vendor/typebox';

definePageMeta({ layout: 'auth-flow' });
useHead({ title: 'Sign in' });

const sessionId = ref<string>('');
const submittedPhone = ref<string>('');
const previousPhone = ref<string>('');

const qs = useQs({
  retpath: Type.Optional(Type.String()),
});
const handleOtpVerified = () => navigateTo(qs.retpath ?? '/');
const handleMissingUser = () => {
  previousPhone.value = submittedPhone.value;

  submittedPhone.value = '';
};
</script>

<template>
  <PhoneInput
    v-if="!submittedPhone"
    :previousPhone="previousPhone"
    @submit="(phone) => ({ sessionId, phone: submittedPhone } = phone)"
  />
  <OtpInput
    v-else
    :submittedPhone="submittedPhone"
    :sessionId="sessionId"
    @goBack="(submittedPhone = ''), (sessionId = '')"
    @verified="handleOtpVerified"
    @noSuchUser="handleMissingUser"
  />
</template>
